import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = []

    initialize() {
        this._bulkController = this._getBulkList();
        this.toggleBulkDelete();
        this.toggleBulkUnsynchronize();
    }

    toggleBulkDelete() {
        const hasExternalUser = this.selectedRows.some(row => row.dataset.external == "true")
        const dropdownButton = this._bulkController.actionsListTarget;
        const deleteButton = dropdownButton.querySelector('[data-title="Delete Users"]');

        hasExternalUser ? deleteButton.classList.add("d-none") : deleteButton.classList.remove("d-none");
    }

    toggleBulkUnsynchronize() {
        const hasExternalUser = this.selectedRows.some(row => row.dataset.external == "false")
        const dropdownButton = this._bulkController.actionsListTarget;
        const unsyncButton = dropdownButton.querySelector('[data-title="Send to Removal Queue"]');

        hasExternalUser ? unsyncButton.classList.add("d-none") : unsyncButton.classList.remove("d-none");
    }

    updateRiskScore() {
        const elements = this.element.querySelectorAll('[data-controller="users--risk-scoring"]');
        caches.delete("user-risk-status");

        Array.from(elements).forEach(element => {
            const riskScoreController = element["users--risk-scoring"];
            element.innerHTML = `<span class="group-label" style="background-color: #F7F7F9; color: #455A64 !important;">Calculating...</span>`;

            riskScoreController.update();
        })
    }

    get selectedRows() {
        let rows = [];
        this._bulkController.itemCheckboxTargets.forEach(item => item.checked && rows.push(item.parentElement.parentElement))

        return rows
    }

    _getBulkList() {
        const list = document.querySelector('[data-controller="bulk-selection"]')
        return this.application.getControllerForElementAndIdentifier(list, 'bulk-selection')
    }
}
import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js/public/assets/scripts/choices.js";

export default class TargetUsers extends Controller {
	static targets = ["list", "select", "emptyBox", "addBtn", "selectedCourses", "totalCourses"];

	initialize() {
		this._initObserver();

		// Init Courses Choices
		this._coursesChoicesInstance = new Choices(this.selectTarget, {
			removeItemButton: true,
			maxItemCount: 9,
			maxItemText: () => "Max 9 courses can be assigned per users",
			resetScrollPosition: false,
		});

		this._setCoursesChoices();
	}

	addCourses() {		if (this._coursesChoicesInstance.config.maxItemCount === 0) return;
		this._coursesChoicesInstance.config.maxItemCount = this._coursesChoicesInstance.config.maxItemCount - this._coursesChoicesInstance.getValue().length;

		this._coursesChoicesInstance.getValue().forEach(element => {
			const template = document.getElementById("course-card-template").content.cloneNode(true);
			let href       = `/${window.location.pathname.split("/")[1]}/training/assets/${element.value}`;
			const isAdmin  = window.location.pathname.split("/")[1] === "admin";
			if (!isAdmin) {
				href = `/${window.location.pathname.split("/")[1]}/training/assets/${element.value}`;
			}

			template.getElementById("course-template-name").innerText               = element.label;
			template.getElementById("course-template-nametwo").innerText            = element.label;
			template.getElementById("course-template-format").lastChild.nodeValue   = element.customProperties.format;
			template.getElementById("course-template-activity").lastChild.nodeValue = element.customProperties.type;
			template.getElementById("course-input").value                           = element.value;
			template.getElementById("course-template-remove").dataset.choiceid      = element.choiceId;
			template.getElementById("course-end-date").classList.add("new-flatpickr");
			template.getElementById("course-end-date").value = element.customProperties.duedate;
			template.getElementById("course-preview").setAttribute("href", href);

			this.selectedCoursesTarget.append(template);
		});
		
		const td = document.querySelector("#trainingDate");
		const flatpickrs = document.getElementsByClassName("new-flatpickr");
		Array.from(flatpickrs).forEach(pickr => {
			pickr.removeAttribute("id");
			pickr.classList.remove("new-flatpickr");
			pickr.classList.add("flatpickr-course");

			flatpickr(pickr, {
				dateFormat: "m/d/Y",
				enableTime: false,
				minDate: new Date().setDate(new Date(td.value).getDate() + 1),
			});
		});

		this.addBtnTarget.disabled = true;
		this._coursesChoicesInstance.getValue().forEach(element => element.active = false);
		this._coursesChoicesInstance.unhighlightAll();
	}

	clearDueDate(event) {
		event.preventDefault();

		let input = event.target.previousElementSibling;
		if (event.target.nodeName == "path") {
			input = event.target.parentElement.previousElementSibling;
		}

		input.value                    = "";
		input._flatpickr.selectedDates = [];
		input._flatpickr.redraw();
	}

	removeCourse(evt) {
		const card = evt.target.parentElement.parentElement.parentElement.parentElement;
		card.remove();

		const choice  = this._coursesChoicesInstance._currentState.items.find(choice => choice.choiceId == evt.target.dataset.choiceid);
		choice.active = true;
		this._coursesChoicesInstance.removeActiveItemsByValue(choice.value);
		this._coursesChoicesInstance.config.maxItemCount = this._coursesChoicesInstance.config.maxItemCount + 1;
	}

	toggleButton(event) {
		const parentTarget = event.target.parentElement.parentElement.parentElement.parentElement;
		const buttonTarget = parentTarget.nextElementSibling.firstElementChild;

		event.target.length > 0 ? buttonTarget.disabled = false : buttonTarget.disabled = true;
	}

	_initObserver() {
		const mutationObserver = new MutationObserver(() => {
			this.selectedCoursesTarget.childElementCount > 0 ? this.emptyBoxTarget.classList.add("d-none") : this.emptyBoxTarget.classList.remove("d-none");

			this.selectedCoursesTarget.childElementCount == 10 ? (
				this._coursesChoicesInstance.input.element.placeholder = this._coursesChoicesInstance.config.maxItemText(),
					this._coursesChoicesInstance.containerOuter.isDisabled = true,
					this._coursesChoicesInstance.input.element.disabled = true,
					this._coursesChoicesInstance.containerOuter.element.classList.add("choices--disabled")
			) : (
				this._coursesChoicesInstance.input.element.placeholder = "Assign a Course",
					this._coursesChoicesInstance.containerOuter.isDisabled = false,
					this._coursesChoicesInstance.input.element.disabled = false,
					this._coursesChoicesInstance.containerOuter.element.classList.remove("choices--disabled")
			);

			this._reorderIndexes();
			this.totalCoursesTarget.innerText = this.selectedCoursesTarget.childElementCount;
		});

		mutationObserver.observe(this.selectedCoursesTarget, {
			attributes: false,
			childList: true,
		});
	}

	_reorderIndexes() {
		const courses  = this.selectedCoursesTarget.querySelectorAll("input[type=\"hidden\"]");
		const duedates = this.selectedCoursesTarget.querySelectorAll("input.flatpickr.flatpickr-input");

		for (let index = 0; index < courses.length; index++) {
			courses[index].name  = `Courses[${index}]`;
			duedates[index].name = `CoursesEnd[${index}]`;
		}
	}

	_setCoursesChoices() {
		const groups  = document.getElementById("original-choices");
		const choices = Array.from(groups.children).map((group, index) => {
			const courses = Array.from(group.children).map(course => {
				return {
					selected: course.selected,
					value: course.value,
					label: course.label,
					customProperties: {
						duedate: course.dataset.duedate,
						format: course.dataset.courseFormat,
						type: course.dataset.courseType,
					},
				};
			});

			return {
				label: group.label,
				id: index + 1,
				disabled: false,
				choices: courses,
			};
		});

		this._coursesChoicesInstance.setChoices(choices, "value", "label", false);
		groups.remove();

		setTimeout(() => this.addCourses(), 0);
		const itemList = this._coursesChoicesInstance.itemList.element;

		while (itemList.firstChild) {
			itemList.removeChild(itemList.firstChild);
		}
	}
}
